import { Box, Button, CircularProgress, TablePagination, TextField, } from "@material-ui/core";
import { DateRangePicker } from "@mantine/dates";
import SearchIcon from "@material-ui/icons/Search";
import CloudDownload from "@material-ui/icons/CloudDownload";
import React, { Component, useState } from "react";
import { APIUrlService } from "../../Services/APIUrlService";
import { TokenService } from "../../TokenService";
import "./DataSearch.css";
import CustomerLocalization from "../../../helpers/CustomerLocalization";
// import SearchTable, { InvoiceData } from "./SearchTable";

export default function DataSearchPage(props: any) {
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState<[Date, Date]>(null);

    function clear() {
        setDateRange(null);
    }

    let tokenService = new TokenService();
    if (!tokenService.isAuthenticated()) {
        props.history.replace("/login");
        return (
            <main />
        );
    }
    else {
        const downloadable = (dateRange && dateRange[0] && dateRange[1]); // Both start and end date are set
        return (
            <main>
                <div className="search-content">
                    <h2>Certificate Data</h2>
                    <p>*Only INVOICED orders can be found.</p>
                    <form className="data-search-form">
                        <DateRangePicker
                            value={dateRange}
                            onChange={setDateRange}
                            size="md"
                            width={500}
                            placeholder="INVOICE DATE (ex: Jan 1, 2022 - Jan 31, 2022)"
                            className="data-search-date-range"
                        />
                        <div className="data-search-date-range-filler"></div>
                        <Button
                            disabled={!downloadable || isLoading}
                            style={{
                                margin: 5, backgroundColor: "#176347", color: "#ffffff",
                                transition: "filter 0.2s ease-in-out",
                                filter: downloadable ? "grayscale(0%)" : "grayscale(100%)"
                            }}
                            className="data-form-search-button"
                            disableRipple={true}
                            size="large"
                            variant="contained"
                            startIcon={isLoading ? <CircularProgress thickness={6} size={22} color="inherit" /> : <CloudDownload />}
                            onClick={async (e) => {
                                e.preventDefault();
                                setIsLoading(true);
                                await downloadExcel(dateRange[0], dateRange[1]);
                                setIsLoading(false);
                            }}
                        >
                            {isLoading ? "Creating download..." : "Download Excel"}
                        </Button>
                        <Button
                            style={{ margin: 5, backgroundColor: "#176347", color: "#ffffff" }}
                            className="data-form-search-button"
                            disableRipple={true}
                            size="large"
                            variant="contained"
                            onClick={clear}>
                            Clear
                        </Button>
                    </form>
                </div>
            </main>
        );
    }
}

/**
 * Fetches and downloads the Excel file from the server.
 * @param startDate Start date to search from.
 * @param endDate End date to search to.
 */
async function downloadExcel(startDate: Date, endDate: Date) {
    const api = new APIUrlService();
    let url = api.GetApiUrl() + "/Invoice/DownloadCertificateData";
    let tokenService = new TokenService();
    let user = tokenService.getUserData();

    /**
     * Formats to American time string
     */
    const toDateFormat = (date: Date) => `${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}-${date.getFullYear()}`;

    const params = new URLSearchParams({
        StartDate: toDateFormat(startDate),
        EndDate: toDateFormat(endDate)
    });

    const fullUrl = url + "?" + params.toString();

    console.log(fullUrl);

    let data: Blob | {
        hasError: boolean,
        message: string
    } = await fetch(fullUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token
        }
    })
    .then(async res => {
        if (res.ok) {
            return res.blob();
        }
        else {
            return res.json();
            // throw new Error("Failed to download excel file.");
        }
    });

    if (!(data instanceof Blob)) {
        console.error(data);
        return;
    }

    const toDate = (date: Date) => `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
    const excelUrl = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = excelUrl;
    link.setAttribute("download", `data-${toDate(startDate)}-${toDate(endDate)}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

import { Button, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import './Login.css';
import { TokenService } from './TokenService';
import { UserData } from './UserData';
import { Header } from './Header';
import { APIUrlService } from './Services/APIUrlService';
import type { History } from 'history';

interface LoginProps {
    history: History;
}

interface LoginState {
    email: string;
    password: string;
    isLoading: boolean;
}

export class Login extends Component<LoginProps, LoginState> {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = { email: '', password: '', isLoading: false }
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginClicked = this.handleLoginClicked.bind(this);
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    handleLoginClicked(e) {
        e.preventDefault();

        const emailString = this.state.email.trim();
        const passString = this.state.password.trim();

        if (!emailString || !passString) {
            return;
        }

        this.setState({ isLoading: true });

        const json = JSON.stringify({ email: emailString, password: passString });

        let urlService = new APIUrlService();

        const xhr = new XMLHttpRequest();
        xhr.open('Post', urlService.GetApiUrl() + '/Auth/Login', true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.onload = () => { this.HandleLoginResponse(xhr.responseText); };
        xhr.onerror = () => { this.HandleLoginError(); };
        xhr.ontimeout = () => { this.HandleLoginError(); };
        xhr.send(json)
    }

    HandleLoginError() {
        this.setState({ isLoading: false });
        alert("Failed to login with these credentials");
    }

    HandleLoginResponse(responseText) {
        this.setState({ isLoading: false });
        var responseObject = JSON.parse(responseText);

        if (responseObject["hasError"] === false) {
            console.log(responseObject);
            let tokenService = new TokenService();
            let userData = new UserData();
            userData.user = responseObject["data"]["email"];
            userData.isAdmin = responseObject["data"]["isAdmin"];
            userData.token = responseObject["data"]["token"];
            userData.expirationDate = responseObject["data"]["expirationDate"];
            userData.defaultUrl = responseObject["data"]["defaultUrl"];
            tokenService.setUserData(userData);

            console.log(userData);

            this.handleAuthRedirect();
        }
        else {
            alert(responseObject["message"]);
        }
    }

    handleAuthRedirect() {
        let tokenService = new TokenService();
        let userData = tokenService.getUserData();

        if (userData.defaultUrl === '' || userData.defaultUrl === undefined || userData.defaultUrl == null) {
            this.props.history.push("/search");
        }
        else {
            this.props.history.push("/" + userData.defaultUrl);
        }
    }

    componentDidMount() {

    }

    render() {
        let tokenService = new TokenService();

        if (tokenService.isAuthenticated()) {
            this.handleAuthRedirect();
            return (<main />);
        }
        else {
            return (
                <main>
                    <Header />
                    <div className="login-content">
                        <h2>Login</h2>

                        <form>
                            <TextField
                                name="email"
                                className="form-control"
                                size={'small'}
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                placeholder="EMAIL"
                                aria-label="EMAIL"
                                label="EMAIL"
                                variant="outlined" />
                            <TextField
                                name="password"
                                type="password"
                                className="form-control"
                                size={'small'}
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                placeholder="PASSWORD"
                                aria-label="PASSWORD"
                                label="PASSWORD"
                                variant="outlined" />

                            <Button
                                type="submit"
                                className="loginButton"
                                disableRipple={true}
                                size="large"
                                variant="text"
                                color="default"
                                startIcon={this.state.isLoading ? < CircularProgress thickness={6} size={22} color="primary" /> : <span />}
                                onClick={this.handleLoginClicked}
                            >
                                Login
                </Button>

                        </form>
                    </div>
                </main>
            );
        }
    }
}

import getBuildMode from "../../helpers/getBuildMode";

export class APIUrlService {
    private productionBaseUrl = 'https://utg-online.dk';
    private stagingBaseUrl = 'http://staging-utg-online.dk';
    private devBaseUrl = 'https://localhost:5555';

    private productionApiUrl = this.productionBaseUrl + '/api/v1';
    private stagingApiUrl = this.stagingBaseUrl + '/api/v1';
    private devApiUrl = this.devBaseUrl + '/api/v1';

    getBaseUrl() {
        const mode = getBuildMode();
        switch (mode) {
            case "production":
                return this.productionBaseUrl;
            case "staging":
                return this.stagingBaseUrl;
            case "development":
                return this.devBaseUrl;
            default:
                throw new Error("Unknown build mode: " + mode);
        }
    }
    
    GetApiUrl() {
        const mode = getBuildMode();
        switch (mode) {
            case "production":
                return this.productionApiUrl;
            case "staging":
                return this.stagingApiUrl;
            case "development":
                return this.devApiUrl;
            default:
                throw new Error("Unknown build mode: " + mode);
        }
    }
}
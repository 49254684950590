import { Button, IconButton, TableCell, TableRow, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface MappingInvoiceRowProps {
    id: any;
    list: any[];
    orderNumber: number;
    customerOrderNumber: number;
    customerOrderItem: number;

    removeRow: (id: any) => void;
    rowDataChanged: (id: any, utgOrderValue: string, skuValue: string, purchaseOrderValue: string) => void;
}

interface MappingInvoiceRowState {
    id: any;
    allLines: any[];
    utgOrderValue: string;
    purchaseOrderValue: string;
    skuValue: string;
    itemDescription: string;
    skuOptions: any[];
    utgOrderOptions: any[];
    purchaseOrderOptions: any[];
    isSkuDisabled: boolean;
    isPurchaseOrderDisabled: boolean;
}

export class MappingInvoiceRow extends Component<MappingInvoiceRowProps, MappingInvoiceRowState> {

    //#region ctor
    constructor(props: MappingInvoiceRowProps) {

        super(props);

        this.state =
        {
            id: this.props.id,
            allLines: this.props.list,
            utgOrderValue: '',
            purchaseOrderValue: '',
            skuValue: '',
            itemDescription: '',
            skuOptions: [],
            utgOrderOptions: [],
            purchaseOrderOptions: [],
            isSkuDisabled: true,
            isPurchaseOrderDisabled: true,
        };

        this.utgOrderValueChanged = this.utgOrderValueChanged.bind(this);
        this.skuValueChanged = this.skuValueChanged.bind(this);
        this.purchaseOrderValueChanged = this.purchaseOrderValueChanged.bind(this);

        this.removeRowClicked = this.removeRowClicked.bind(this);
    }

    async componentDidMount() {
        await this.setupRowData();
    }

    async setupRowData() {
        if (this.state.allLines === null || this.state.allLines === undefined || this.state.allLines.length === 0) {
            return;
        }

        await this.initUTGOrderValues();

        if (this.props.orderNumber !== undefined) {            
            await this.utgOrderValueChanged(this.props.orderNumber);
        }

        if (this.props.customerOrderItem !== undefined) {            
            await this.skuValueChanged(this.props.customerOrderItem);
        }

        if (this.props.customerOrderNumber !== undefined) {            
            await this.purchaseOrderValueChanged(this.props.customerOrderNumber);
        }
    }

    //#endregion

    removeRowClicked() {
        this.props.removeRow(this.state.id);
    }

    dataChanged() {
        this.props.rowDataChanged(this.state.id, this.state.utgOrderValue, this.state.skuValue, this.state.purchaseOrderValue);
    }

    //#region UTG Order Actions
    // orderNo === UTGOrder

    clearUTGOrderValue() {        
        this.setState({
            isSkuDisabled: true,
            isPurchaseOrderDisabled: true,
            skuOptions: [],
            purchaseOrderOptions: [],
            skuValue: '',
            purchaseOrderValue: '',
            utgOrderValue: ''
        });
    }

    async initUTGOrderValues() {        
        var _utgOrderOptions = [];
        _utgOrderOptions = this.state.allLines.map(item => item.orderNo)
            .filter((value, index, self) => self.indexOf(value) === index);
        this.setState({ utgOrderOptions: _utgOrderOptions });
    }

    async utgOrderValueChanged(value) {        
        if (value !== this.state.utgOrderValue) {
            var item = this.state.allLines.find(item => item.orderNo === value);
            if (item === undefined) {
                return;
            }
           this.setState({ utgOrderValue: value }, this.processNewUTGOrderValue);
        }
    }

    async processNewUTGOrderValue() {
        this.dataChanged();

        await this.clearPurchaseOrderValue();

        await this.clearSkuValue();

        await this.initSkuOptions();
    }

    //#endregion

    //#region Sku Actions
    // sku === customerItemNo

    async initSkuOptions() {        
        var _validSkuOptions = [];

        this.state.allLines.map((item) => {
            if (item.orderNo === this.state.utgOrderValue) {
                _validSkuOptions.push(item);
            }
        });

        var _skuOptions = _validSkuOptions.map(item => item.customersItemNo)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.setState({
            skuOptions: _skuOptions,
            isSkuDisabled: false,
        });

        if (_skuOptions.length === 1) {
            this.skuValueChanged(_skuOptions[0]);
        }
    }

    async clearSkuValue() {        
        this.setState({
            skuValue: '',
            isSkuDisabled: true,
            skuOptions: []
        });
    }

    async skuValueChanged(value) {        
        if (value !== this.state.skuValue) {
            var item = this.state.allLines.find(item => item.customersItemNo === value);

            if (item === undefined) {
                return;
            }
            this.setState({ skuValue: value }, this.processNewSkuValue);
        }
    }

    async processNewSkuValue() {     
        this.dataChanged();

        await this.clearPurchaseOrderValue();

        await this.initPurchaseOrderOptions();
    }

    //#endregion

    //#region Purchase Order Actions
    // purchaseOrder === customersOrderNo

    async initPurchaseOrderOptions() {        
        var _validPurchaseOrderOptions = [];

        this.state.allLines.map((item) => {
            if (item.orderNo === this.state.utgOrderValue && item.customersItemNo === this.state.skuValue) {
                _validPurchaseOrderOptions.push(item);
            }
        });

        var _purchaseOrderOptions = _validPurchaseOrderOptions.map(item => item.customersOrderNo)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.setState({
            purchaseOrderOptions: _purchaseOrderOptions,
            isPurchaseOrderDisabled: false
        });

        if (_purchaseOrderOptions.length === 1) {
            this.purchaseOrderValueChanged(_purchaseOrderOptions[0]);
        }
    }

    async clearPurchaseOrderValue() {        
        this.setState({
            purchaseOrderValue:'',
            purchaseOrderOptions:[],
            isPurchaseOrderDisabled: true
        });
    }

    async purchaseOrderValueChanged(value) {        
        if (value !== this.state.purchaseOrderValue) {
            var item = this.state.allLines.find(item => item.customersOrderNo === value);

            if (item === undefined) {
                return;
            }

            this.setState({
                purchaseOrderValue: value,
                itemDescription:item.itemDescription
            });

            this.dataChanged();
        }
    }

    //#endregion

    render() {
        return (
            <React.Fragment key={this.state.id}>
                <TableRow key={this.state.id}>
                    <TableCell align="right">
                        <Autocomplete
                            disableClearable
                            id={"orderAutocomplete" + this.state.id}
                            value={this.state.utgOrderValue}
                            onInputChange={(event, newInputValue) => {
                                this.utgOrderValueChanged(newInputValue);
                            }}
                            options={this.state.utgOrderOptions}
                            getOptionLabel={option => option}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="form-control" size={'small'} />}
                        />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Autocomplete
                            disableClearable
                            disabled={this.state.isSkuDisabled}
                            id={"skuAutocomplete" + this.state.id}
                            value={this.state.skuValue}
                            onInputChange={(event, newInputValue) => {
                                this.skuValueChanged(newInputValue);
                            }}
                            options={this.state.skuOptions}
                            getOptionLabel={option => option}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="form-control" size={'small'}

                            />}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <Autocomplete
                            disableClearable
                            disabled={this.state.isPurchaseOrderDisabled}
                            id={"customerOrderAutocomplete" + this.state.id}
                            options={this.state.purchaseOrderOptions}
                            value={this.state.purchaseOrderValue}
                            onInputChange={(event, newInputValue) => {
                                this.purchaseOrderValueChanged(newInputValue);
                            }}
                            getOptionLabel={option => option}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="form-control" size={'small'} />}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <TextField
                            disabled
                            variant="outlined"
                            name="email"
                            className="form-control"
                            size={'small'}
                            value={this.state.itemDescription}>
                        </TextField>
                    </TableCell>
                    <TableCell align="right">
                        <IconButton
                            aria-haspopup="true"
                            onClick={this.removeRowClicked.bind(this)}
                            style={{ backgroundColor: '#176347' }}
                            type="submit">
                            <DeleteForeverIcon
                                fontSize={"medium" as any} // TypeScript doesn't recognize "medium" as valid for some reason.
                                style={{ color: '#ffffff', borderWidth: '0px' }} />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
}
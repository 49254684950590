import React, { Component } from 'react';
import { TokenService } from './TokenService';
import type { History } from 'history';


interface LogoutProps {
    history: History;
}

export class Logout extends Component<LogoutProps> {
    static displayName = Logout.name;

    constructor(props: LogoutProps) {
        super(props);
    }

    render() {
        let tokenService = new TokenService();

        tokenService.logout();

        this.props.history.replace("/login");

        return (<main />);
    }
}

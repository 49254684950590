import { Box, Button, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, TablePagination, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { Header } from '../Header';
import { APIUrlService } from '../Services/APIUrlService';
import { TokenService } from '../TokenService';
import { TrimImageGrid } from './TrimImageGrid';


export class TrimImageGallery extends Component {
    static displayName = TrimImageGallery.name;

    constructor(props) {
        super(props);

        this.state = {
            searchString: '',
            searchResponse: null,
            hasResults: false,
            currentPage: 0,
            itemsPerPage: 10,
            totalItems: 0,
            sortOrderValue:"1",
        };

        this.handleSearchClicked = this.handleSearchClicked.bind(this);
        this.handleSearchStringChanged = this.handleSearchStringChanged.bind(this);
        this.handleClearClicked = this.handleClearClicked.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSortOrderValueChanged = this.handleSortOrderValueChanged.bind(this);
    }

    handleSortOrderValueChanged(e) {
        this.setState({ sortOrderValue: e.target.value });
    }

    handleChangePage = (event, page) => {
        event.preventDefault();
        this.setState({ currentPage: page }, this.loadImageList);
    }

    handleChangeRowsPerPage(e) {
        this.setState({ itemsPerPage: e.target.value }, this.loadImageList);
    }

    handleSearchStringChanged(e) {
        this.setState({ searchString: e.target.value });
    }

    handleClearClicked(e) {
        e.preventDefault();

        this.setState({ searchString: '', currentPage: 0 }, this.loadImageList);
    }

    componentDidMount() {
        this.loadImageList();
    }

    //#region Load Image List

    handleSearchClicked(e) {
        e.preventDefault();

        this.setState({ currentPage: 0 }, this.loadImageList);
    }

    loadImageList() {

        this.setState({
            isLoading: true,
            hasResults: false,
            searchResponse: null
        });

        let searchParamValue = this.state.searchString.trim();

        let tokenService = new TokenService();
        let take = this.state.itemsPerPage;
        let skip = this.state.currentPage * this.state.itemsPerPage;
        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/PrintImage/Trims');
        url.searchParams.set('searchString', searchParamValue);
        url.searchParams.set('take', take);
        url.searchParams.set('skip', skip);
        url.searchParams.set('sortOrder', this.state.sortOrderValue);

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => { this.HandleSearchResponse(xhr); };
        xhr.onerror = () => { this.HandleSearchError(); };
        xhr.ontimeout = () => { this.HandleSearchError(); };
        xhr.send();
    }

    HandleSearchError() {
        this.setState({ isLoading: false });
        alert("Search request has failed.");
    }

    HandleSearchResponse(xhr) {
        this.setState({ isLoading: false });

        var responseObject = JSON.parse(xhr.responseText);

        if (responseObject["hasError"] === false) {
            if (typeof responseObject["data"] !== 'undefined' && responseObject["data"].length > 0) {
                this.setState({
                    searchResponse: responseObject,
                    hasResults: true,
                    totalItems: responseObject["itemCount"]
                });
            }
        }
        else {
            this.setState({ hasResults: false });

            if (xhr.status == 401) {
                this.props.history.replace("/logout");
            }
            else {
                alert(responseObject["message"]);
            }
        }
    }

    //#endregion 

    render() {
        let searchResults = this.state.hasResults
            ? <TrimImageGrid props={this.state.searchResponse.data} />
            : <h3>No results</h3>;
        return (
            <main>
                <Header props={this.props} />
                <div className="search-content">
                    <h2>TRIMS</h2>
                    <form>
                        <TextField
                            name="email"
                            className="form-control"
                            size={'small'}
                            value={this.state.searchString}
                            onChange={this.handleSearchStringChanged}
                            placeholder="Search file name"
                            aria-label="Search file name"
                            label="Search file name"
                            variant="outlined" />
                        <Button
                            style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                            type="submit"
                            className="search-button"
                            disableRipple={true}
                            size="large"
                            variant="contained"
                            startIcon={this.state.isLoading ? <CircularProgress thickness={6} size={22} color='inherit' /> : <SearchIcon />}
                            onClick={this.handleSearchClicked}>
                            Search
                                </Button>
                        <Button
                            style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                            className="search-button"
                            disableRipple={true}
                            size="large"
                            variant="contained"
                            onClick={this.handleClearClicked}>
                            Clear
                                </Button>
                    </form>
                    <Box my={4}>
                        <Grid container justify="flex-end" direction="row">                        
                            <Grid item >
                                <RadioGroup aria-label="sort order" name="sort-order" row value={this.state.sortOrderValue} onChange={this.handleSortOrderValueChanged}>
                                    <FormControlLabel value="0" control={<Radio />} label="Last added print" />
                                    <FormControlLabel value="1" control={<Radio />} label="Newest file" />
                            </RadioGroup>
                        </Grid>
                            <Grid >
                            <Box>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    component="div"
                                    count={this.state.totalItems}
                                    page={this.state.currentPage}
                                    onChangePage={this.handleChangePage}
                                    rowsPerPage={this.state.itemsPerPage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    </Box>

                    {searchResults}
                </div>
            </main>
        );
    }
}
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, TableFooter, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import PDFObject from 'pdfobject';
import './EditMappingPage.css';
import { Header } from './Header';
import { MappingInvoiceRow } from './Models/MappingInvoiceRow';
import { APIUrlService } from './Services/APIUrlService';
import { TokenService } from './TokenService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import type { Location, History } from 'history';

interface EditMappingPageProps {
    location: any;
    history: History;
}

interface EditMappingPageState {
    declarationId: string,
    customer: string,

    lastSavedString: string,
    pageTitle: string,
    mappingData: { data: any },
    certificateList: any,
    invoiceLinesList: {
        data: any[]
    }
    isCertificateDropdownDisabled: boolean,
    skuNo: string, orderNo: string,
    description: string,
    searchResponse: string,
    skuList: any[],
    orderList: any[],
    customerOrderList: any[],
    selectedCertificate: string,
    selectedCertificateId: string,
    certificateDropdownSource: any[],
    invoiceRows: any[],
    declarationPDFUrl: string,
    tableStatus: boolean
    newLineId: number,

    // Optional
    isLoading?: boolean,
    isDone?: boolean,
    hasResults?: boolean,
    isCertificateDisabled?: boolean,
}

export class EditMappingPage extends Component<EditMappingPageProps, EditMappingPageState> {
    static displayName = EditMappingPage.name;
    newInvoiceLineJson = '{"id":"","timestamp":null,"invoiceNo":"","lineNo": 0,"orderNo": "","customersOrderNo": "","tcCertificateCode": "","sellToCustomerNo": "","sellToCustomerName": "","itemNo": "","itemDescription": "","customersItemNo": "","tcCertificateDescription": "","certificates": null,"customerId":""}';

    constructor(props: EditMappingPageProps) {
        super(props);

        this.state = {
            declarationId: this.props.location.state.declarationId,
            customer: this.props.location.state.customer,

            lastSavedString: "last saved: ",
            pageTitle: "Perform mapping for declaration letter ",
            mappingData: undefined,
            certificateList: [],
            invoiceLinesList: undefined,
            isCertificateDropdownDisabled: true,
            skuNo: '', orderNo: '',
            description: '',
            searchResponse: '',
            skuList: [],
            orderList: [],
            customerOrderList: [],
            selectedCertificate: '',
            selectedCertificateId: '',
            certificateDropdownSource: [],
            invoiceRows: [],
            declarationPDFUrl: '',
            isDone: false,
            newLineId: 0,
            tableStatus: true
        };

        console.log(this.state);
        
        this.handleSelectedCertificateChange = this.handleSelectedCertificateChange.bind(this);
        this.addNewRow = this.addNewRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.handleSaveClicked = this.handleSaveClicked.bind(this);
        this.checkBoxChanged = this.checkBoxChanged.bind(this);
        this.openNewWindowClicked = this.openNewWindowClicked.bind(this);
        this.rowDataChanged = this.rowDataChanged.bind(this);
    }

    rowDataChanged(id, utgOrder, sku, purchaseOrder) {
        var _updatedRows = [];

        this.state.invoiceRows.map((item) => {
            if (item.id === id) {
                item.orderNo = utgOrder;
                item.customersOrderNo = purchaseOrder;
                item.customersItemNo = sku;
            }
            _updatedRows.push(item);
        });

        this.setState({ invoiceRows: _updatedRows });
    }

    openNewWindowClicked() {

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/DeclarationLetter/pdf/' + this.state.declarationId);

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url as any, true);
        xhr.responseType = "blob";
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"]);
        // TODO: Add customer header
        xhr.setRequestHeader("Customer", this.state.customer);
        xhr.onload = () => { this.openNewWindowClickedHandleResponse(xhr.response, xhr.status); };
        xhr.send();
    }

    openNewWindowClickedHandleResponse(responseObject, status) {
        if (status == 401) {
            this.props.history.replace("/logout");
            return;
        }

        const myBlob = new Blob([responseObject], {
            type: 'application/pdf'
        });

        var blobUrl = URL.createObjectURL(myBlob);
        this.setState({ declarationPDFUrl: blobUrl })

        PDFObject.embed(this.state.declarationPDFUrl, `#pdf-container`);
    }

    checkBoxChanged() {
        this.setState({ isDone: !this.state.isDone });
    }

    handleSaveClicked() {
        this.setState({ isLoading: true });

        console.log("handleSaveClicked");
        console.log(this.state.selectedCertificateId);

        if (this.state.selectedCertificateId === '' || this.state.selectedCertificateId === undefined) {
            alert("Can't save mapping without choosing a certificate");
            return;
        }

        var _tableRows = [];
        this.state.invoiceRows.map((row) => {
            _tableRows.push({
                id: row.id,
                orderNumber: row.orderNo,
                customerOrderItem: row.customersItemNo,
                customerOrderNumber: row.customersOrderNo
            });

        });



        var data = {
            DeclarationLetterId: this.state.declarationId,
            CertificateId: this.state.selectedCertificateId,
            InvoiceLines: [],
            IsDone: this.state.isDone
        };

        _tableRows.map((row) => {
            var item = this.state.invoiceLinesList.data.find(item => item.orderNo == row.orderNumber && item.customersItemNo == row.customerOrderItem && item.customersOrderNo == row.customerOrderNumber)

            if (item !== undefined) {
                data.InvoiceLines.push(item.id);
            }
        });

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let updateDeclarationUrl = new URL(urlService.GetApiUrl() + '/DeclarationLetter');

        const updateDeclarationXhr = new XMLHttpRequest();

        updateDeclarationXhr.open('Post', updateDeclarationUrl as any, true);
        updateDeclarationXhr.setRequestHeader("Accept", "application/json");
        updateDeclarationXhr.setRequestHeader("Content-type", "application/json");
        updateDeclarationXhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        updateDeclarationXhr.setRequestHeader("Customer", this.state.customer);
        updateDeclarationXhr.onload = () => { this.HandleUpdateSuccess(); };
        updateDeclarationXhr.onerror = () => {
            (this as any).HandleDeclarationDataError("handleSaveClicked");
        };
        updateDeclarationXhr.ontimeout = () => { this.handleXhrError("handleSaveClicked"); };
        updateDeclarationXhr.send(JSON.stringify(data));
    }

    HandleUpdateSuccess() {
        this.props.history.replace("/mapping/list");
    }

    removeRow(id) {
        var _filteredLines = [];
        console.log(this.state.invoiceRows);
        this.state.invoiceRows.map((item) => {
            if (item.id !== id) {
                _filteredLines.push(item);
            }
        });

        this.setState({ invoiceRows: _filteredLines }, () => {
            console.log(this.state.invoiceRows);
        });
    }

    addNewRow() {
        var currentRows = this.state.invoiceRows;

        var newLine = JSON.parse(this.newInvoiceLineJson);
        newLine.id = "newLine" + this.state.newLineId;
        currentRows.push(newLine);

        this.setState({ invoiceRows: currentRows });
        this.setState({ newLineId: this.state.newLineId + 1 });
    }

    componentDidMount() {
        this.loadCertificatesList();

        this.loadInvoiceLines();

        this.loadDeclarationData();

        this.openNewWindowClicked();
    }

    //#region Load Invoices

    loadInvoiceLines() {
        this.setState({ isLoading: true });

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let invoiceUrl = new URL(urlService.GetApiUrl() + '/Invoice');

        const invoiceXhr = new XMLHttpRequest();

        invoiceXhr.open('Get', invoiceUrl as any, true);
        invoiceXhr.setRequestHeader("Accept", "application/json");
        invoiceXhr.setRequestHeader("Content-type", "application/json");
        invoiceXhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        invoiceXhr.setRequestHeader("Customer", this.state.customer);
        invoiceXhr.onload = () => { this.HandleInvoiceDataResponse(invoiceXhr); };
        invoiceXhr.onerror = () => {
            (this as any).HandleDeclarationDataError("loadInvoiceLines");
        };
        invoiceXhr.ontimeout = () => { this.handleXhrError("loadInvoiceLines"); };
        invoiceXhr.send();
    }

    HandleInvoiceDataResponse(xhr) {
        this.setState({ isLoading: false });

        var invoiceResponseObject = JSON.parse(xhr.responseText);

        if (invoiceResponseObject["hasError"] === false) {
            if (typeof invoiceResponseObject["data"] !== 'undefined') {
                this.setState({ invoiceLinesList: invoiceResponseObject });
            }
        }
        else {
            this.setState({ hasResults: false });

            if (xhr.status == 401) {
                this.props.history.replace("/logout");
            }
            else {
                alert(invoiceResponseObject["message"]);
            }
        }
    }

    //#endregion

    //#region Load Certificates

    loadCertificatesList() {
        this.setState({ isLoading: true });

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let certificateUrl = new URL(urlService.GetApiUrl() + '/Certificate');

        const certificateXhr = new XMLHttpRequest();

        certificateXhr.open('Get', certificateUrl as any, true);
        certificateXhr.setRequestHeader("Accept", "application/json");
        certificateXhr.setRequestHeader("Content-type", "application/json");
        certificateXhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        certificateXhr.setRequestHeader("Customer", this.state.customer);
        certificateXhr.onload = () => { this.HandleCertificateListDataResponse(certificateXhr); };
        certificateXhr.onerror = () => {
            (this as any).HandleDeclarationDataError("loadCertificatesList");
        };
        certificateXhr.ontimeout = () => { this.handleXhrError("loadCertificatesList"); };
        certificateXhr.send();
    }

    HandleCertificateListDataResponse(xhr) {
        this.setState({ isLoading: false });

        var certificateResponseObject = JSON.parse(xhr.responseText);

        if (certificateResponseObject["hasError"] === false) {
            if (typeof certificateResponseObject["data"] !== 'undefined') {
                this.setState({ certificateList: certificateResponseObject });
                this.processCertificateData();
            }
        }
        else {
            this.setState({ hasResults: false });

            if (xhr.status == 401) {
                this.props.history.replace("/logout");
            }
            else {
                alert(certificateResponseObject["message"]);
            }
        }
    }

    processCertificateData() {
        if (this.state.certificateList.length == 0) {
            return;
        }

        var _dropdownSource = [];

        this.state.certificateList.data.map((item) => {
            _dropdownSource.push(item.fileName);
        });

        this.setState({
            isCertificateDropdownDisabled: false,
            certificateDropdownSource: _dropdownSource
        });
    }


    handleSelectedCertificateChange(e) {
        if (e !== undefined && e !== '') {
            console.log(this.state.certificateList);
            var _certificate = this.state.certificateList.data.find(item => item.fileName === e);

            this.setState({ selectedCertificate: e, selectedCertificateId: _certificate.id });
        }
    }

    //#endregion

    // #region Load Declaration data

    loadDeclarationData() {
        this.setState({ isLoading: true });

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/DeclarationLetter/' + this.state.declarationId);

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url as any, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.setRequestHeader("Customer", this.state.customer);
        xhr.onload = () => { this.HandleDeclarationDataResponse(xhr); };
        xhr.onerror = () => {
            (this as any).HandleDeclarationDataError("loadDeclarationData");
        };
        xhr.ontimeout = () => { this.handleXhrError("loadDeclarationData"); };
        xhr.send();
    }

    handleXhrError(requestName) {
        this.setState({ isLoading: false });
        alert(requestName + " request has failed.");
    }

    HandleDeclarationDataResponse(xhr) {
        this.setState({ isLoading: false });

        var responseObject = JSON.parse(xhr.responseText);

        if (responseObject["hasError"] === false) {
            if (typeof responseObject["data"] !== 'undefined') {
                this.setState({ mappingData: responseObject });
                this.processDeclarationData();
            }
        }
        else {
            this.setState({ hasResults: false });

            if (xhr.status == 401) {
                this.props.history.replace("/logout");
            }
            else {
                alert(responseObject["message"]);
            }
        }
    }

    processDeclarationData() {
        if (this.state.mappingData === undefined) {
            return;
        }

        if (this.state.mappingData.data.certificate !== undefined && this.state.mappingData.data.certificate !== null) {
            this.setState({ selectedCertificate: this.state.mappingData.data.certificate.fileName });
        }
        this.setState({ pageTitle: "Perform mapping for declaration letter " + this.state.mappingData.data.filename });
        this.setState({ lastSavedString: "last saved: " + this.state.mappingData.data.lastSavedDate });
        this.setState({ isDone: this.state.mappingData.data.isDone });
        this.addExistingRows();
    }

    addExistingRows() {
        var currentRows = this.state.invoiceRows;

        this.state.mappingData.data.certificateInvoiceLines.map((item) => {
            var newLine = JSON.parse(this.newInvoiceLineJson);
            newLine.id = item.id;
            newLine.orderNumber = item.orderNo;
            newLine.customerOrderItem = item.customersItemNo;
            newLine.customerOrderNumber = item.customersOrderNo;
            currentRows.push(newLine);
        });

        this.setState({ invoiceRows: currentRows });
        this.setState({ newLineId: this.state.newLineId + 1 });
    }

    //#endregion

    //    value = { value }
    //    onChange = {(event, newValue) => {
    //    setValue(newValue);
    //}}
    //inputValue = { inputValue }
    //onInputChange = {(event, newInputValue) => {
    //    setInputValue(newInputValue);
    //}}

    renderInvoiceTable() {
        if (this.state.tableStatus) {
            return (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width='25%'>UTG ORDER</TableCell>
                                <TableCell width='25%'>SKU</TableCell>
                                <TableCell width='25%'>PURCHASE ORDER</TableCell>
                                <TableCell width='25%'>DESCRIPTION</TableCell>
                                <TableCell width='50px'>ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.invoiceRows.map((row) => (
                                <MappingInvoiceRow list={this.state.invoiceLinesList.data} id={row.id} removeRow={this.removeRow}
                                    orderNumber={row.orderNumber} customerOrderItem={row.customerOrderItem} customerOrderNumber={row.customerOrderNumber} rowDataChanged={this.rowDataChanged}
                                    key={row.id}
                                />
                            ))}
                        </TableBody>
                        <TableFooter {...{ align: "center" }}>
                            <Button
                                onClick={this.addNewRow}
                                type="submit"
                                className="search-button"
                                disableRipple={true}
                                size="large">
                                ADD NEW ROW
                            </Button>
                        </TableFooter>
                    </Table>
                </TableContainer>
            );
        }
        else {
            return (<div />)
        }
    }

    render() {
        let invoiceTable = (<div />);
        if (this.state.invoiceLinesList !== undefined && this.state.mappingData != undefined) {
            invoiceTable = this.renderInvoiceTable();
        }

        return (
            <main>
                <Header props={this.props} />
                <div style={{ minHeight: 950 }}>
                    <Box mx={1} >
                        <h4>{this.state.pageTitle}</h4>
                    </Box>

                    <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
                        <Grid item xs={6}>
                            <Box mx={1} >
                                This document is type: Declaration Letter
                            </Box>
                        </Grid>
                        <Grid item container spacing={3} direction="row" justify="flex-end" alignItems="center" xs={6}>
                            <Grid item>
                                {this.state.lastSavedString}
                            </Grid>
                            <Grid item>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox onChange={this.checkBoxChanged} checked={this.state.isDone} />}
                                        label="Matching is DONE"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={this.handleSaveClicked}
                                    type="submit"
                                    className="search-button"
                                    disableRipple={true}
                                    size="large"
                                    variant="outlined">
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} direction="row" alignItems="center">
                        <Grid item xs={9}>
                        </Grid>
                        <Grid item xs={3}>
                            <Box my={2} mx={1} >
                                <Autocomplete
                                    disableClearable
                                    disabled={this.state.isCertificateDisabled}
                                    id="certAutocomplete"
                                    value={this.state.selectedCertificate}
                                    onInputChange={(event, newInputValue) => {
                                        this.handleSelectedCertificateChange(newInputValue);
                                    }}
                                    options={this.state.certificateDropdownSource}
                                    getOptionLabel={option => option}
                                    renderInput={(params) => <TextField {...params} variant="outlined" className="form-control" size={'small'}
                                    />}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2} style={{ height: 950 }}>

                        <Grid item xs={4} style={{ height: 950 }}>
                            <Box bgcolor="text.secondary" boxShadow={2} style={{ height: 950 }} mx={1}>
                                <div style={{ width: '100%', height: 950 }} id='pdf-container' />
                                {/*<PDFDocument props={this.state.declarationId} />*/}
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            {invoiceTable}
                        </Grid>
                    </Grid>
                </div>
            </main >
        );
    }
}
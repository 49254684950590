export class UserData {
    token: string;
    expirationDate: string;
    user: string;
    isAdmin: Boolean;
    defaultUrl: string;

    public authHeaders() {
        return {
            'Authorization': `Bearer ${this.token}`
        };
    }
}
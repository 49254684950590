import './Splash.css';
import Logo from './Logo';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

function Splash() {
    const {id} = useParams<{ id: string }>();
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            history.push(`/login`)
        }, 3000);
    }, [id])

    return (
        <div className="viewport">
            <div className="App-logo">
                <Logo/>
            </div>
            <div className="loading-text">
                Loading customer online portal..
            </div>
        </div>
    )
};

export default Splash

import { Box, Button, CircularProgress, MenuItem, TablePagination, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { Header } from './Header';
import './MappingListPage.css';
import { TokenService } from './TokenService';
import { APIUrlService } from './Services/APIUrlService';
import type { History } from 'history';
import { customerParser, getCustomers } from '../helpers/CustomerLocalization';

interface MappingListPageProps {
    history: History;
}

interface MappingListPageState {
    declarationSearchString: string,
    searchStatus: number,
    searchCustomer: string,
    hasResults: boolean,
    isLoading: boolean,
    currentPage: number,
    itemsPerPage: number,
    totalItems: number
    searchResponse: { data: SearchResult[] },
}

interface SearchResult {
    id: number;
    filename: string;
    dateFirstSeen: Date;
    certificate: string;
    orderLineCount: string;
    status: string;
}

export class MappingListPage extends Component<MappingListPageProps, MappingListPageState> {
    static displayName = MappingListPage.name;

    constructor(props: MappingListPageProps) {
        super(props);
        this.state = {
            declarationSearchString: '',
            searchStatus: 3,
            searchCustomer: getCustomers()[0],
            hasResults: false,
            searchResponse: null,
            isLoading: false,
            currentPage: 0,
            itemsPerPage: 10,
            totalItems: 0
        };

        this.handleDeclarationSearchString = this.handleDeclarationSearchString.bind(this);
        this.handleSearchStatus = this.handleSearchStatus.bind(this);
        this.handleSearchCustomer = this.handleSearchCustomer.bind(this);
        this.handleSearchClicked = this.handleSearchClicked.bind(this);
        this.handleCertButtonClick = this.handleCertButtonClick.bind(this);
        this.handleClearClicked = this.handleClearClicked.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    componentDidMount() {
        this.loadSearchResults();
    }

    handleChangePage = (event, page) => {
        event.preventDefault();
        this.setState({ currentPage: page }, this.loadSearchResults);
    }

    handleChangeRowsPerPage(e) {
        this.setState({ itemsPerPage: e.target.value }, this.loadSearchResults);
    }

    handleClearClicked() {
        this.setState({
            hasResults: false,
            searchResponse: null,
            declarationSearchString: '',
            searchStatus: 3,
            currentPage: 0
        }, this.loadSearchResults);
    }

    handleCertButtonClick(id) {
        this.props.history.push({
            pathname: "/mapping/edit",
            state: { declarationId: id, customer: this.state.searchCustomer }
        });
    }


    statuses = [
        {
            value: 0,
            label: 'AWAITING',
        },
        {
            value: 1,
            label: 'DONE',
        },
        {
            value: 2,
            label: 'NOT TO BE MAPPED',
        },
        {
            value: 3,
            label: 'ALL',
        },
    ];

    handleDeclarationSearchString(e) {
        this.setState({ declarationSearchString: e.target.value });
    }

    handleSearchStatus(e) {
        this.setState({ searchStatus: e.target.value });
    }

    handleSearchCustomer(e) {
        this.setState({ searchCustomer: e.target.value });
        this.handleSearchClicked(e);
    }

    handleSearchClicked(e) {
        e.preventDefault();

        this.setState({ currentPage: 0 }, this.loadSearchResults);
    }

    loadSearchResults() {
        this.setState({
            isLoading: true,
            searchResponse: null,
            hasResults: false
        });

        let tokenService = new TokenService();
        let take = this.state.itemsPerPage;
        let skip = this.state.currentPage * this.state.itemsPerPage;
        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/DeclarationLetter');
        url.searchParams.set('status', this.state.searchStatus?.toString());
        url.searchParams.set('searchString', this.state.declarationSearchString.trim());
        url.searchParams.set('take', take?.toString());
        url.searchParams.set('skip', skip?.toString());
        

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url as any, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"]);
        xhr.setRequestHeader("Customer", this.state.searchCustomer);
        xhr.onload = () => { this.HandleSearchResponse(xhr); };
        xhr.onerror = () => { this.HandleSearchError(); };
        xhr.ontimeout = () => { this.HandleSearchError(); };
        xhr.send();
    }

    HandleSearchError() {
        this.setState({ isLoading: false });
        alert("Search request has failed.");
    }

    HandleSearchResponse(xhr) {
        this.setState({ isLoading: false });

        var responseObject = JSON.parse(xhr.responseText);

        if (responseObject["hasError"] === false) {
            if (typeof responseObject["data"] !== 'undefined' && responseObject["data"].length > 0) {
                this.setState({
                    searchResponse: responseObject,
                    hasResults: true,
                    totalItems: responseObject["itemCount"]
                });
            }
        }
        else {
            this.setState({ hasResults: false });

            if (xhr.status == 401) {
                this.props.history.replace("/logout");
            }
            else {
                alert(responseObject["message"]);
            }
        }
    }

    renderInvoiceTable(model) {
        return (

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>NAME</TableCell>
                            <TableCell align="right">DATE FIRST SEEN</TableCell>
                            <TableCell align="right">CORRESPONDING CERTIFICATE</TableCell>
                            <TableCell align="right">ATTACHED ORDER LINES</TableCell>
                            <TableCell align="right">STATUS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.searchResponse.data.map((row) => (
                            <React.Fragment>
                                <TableRow key={row.id} onClick={this.handleCertButtonClick.bind(this, row.id)} style={{ cursor: 'pointer' }}>
                                    <TableCell component="th" scope="row">
                                        {row.filename}
                                    </TableCell>
                                    <TableCell align="right">{row.dateFirstSeen}</TableCell>
                                    <TableCell align="right">{row.certificate}</TableCell>
                                    <TableCell align="right">{row.orderLineCount}</TableCell>
                                    <TableCell align="right">{row.status}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        );
    }

    render() {
        let tokenService = new TokenService();
        if (!tokenService.isAuthenticated()) {
            this.props.history.replace("/login");
            return (
                <main />
            );
        }
        else {
            let searchResults = this.state.hasResults
                ? this.renderInvoiceTable(this.state.searchResponse)
                : <h3>No results</h3>;
            return (
                <main>
                    <Header props={this.props} />
                    <div className="search-content">
                        <h2>Declaration letters</h2>
                        <form>
                            <TextField
                                label="Search declaration letter"
                                name="email"
                                className="form-control"
                                size={'small'}
                                value={this.state.declarationSearchString}
                                onChange={this.handleDeclarationSearchString}
                                aria-label="Search declaration letter"
                                variant="outlined" />
                            <TextField
                                label="Show only status"
                                select
                                name="email"
                                className="form-control"
                                size={'small'}
                                value={this.state.searchStatus}
                                onChange={this.handleSearchStatus}
                                aria-label="Show only status"
                                variant="outlined" >
                                {this.statuses.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Customer"
                                select
                                name="customer"
                                className="form-control"
                                size={'small'}
                                value={this.state.searchCustomer}
                                onChange={this.handleSearchCustomer}
                                aria-label="Customer"
                                variant="outlined" >
                                {getCustomers().map((customer) => (
                                    <MenuItem key={customer} value={customer}>
                                        {customer.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button
                                style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                type="submit"
                                className="search-button"
                                disableRipple={true}
                                size="large"
                                variant="outlined"
                                color="default"
                                startIcon={this.state.isLoading ? <CircularProgress thickness={6} size={22} color="primary" /> : <SearchIcon />}
                                onClick={this.handleSearchClicked}>
                                Search
                                </Button>
                            <Button
                                style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                className="search-button"
                                disableRipple={true}
                                size="large"
                                variant="outlined"
                                color="default"
                                onClick={this.handleClearClicked}>
                                Clear
                                </Button>
                        </form>
                        <Box my={3}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                component="div"
                                count={this.state.totalItems}
                                page={this.state.currentPage}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={this.state.itemsPerPage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Box>
                        {searchResults}
                    </div>
                </main>
            );
        }
    }
}
import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { Component } from 'react';
import { Header } from "../Header";
import { APIUrlService } from "../Services/APIUrlService";
import { TokenService } from "../TokenService";

export class EditProfilePage extends Component {

    urlOptions = [
        {
            value: 'user-management',
            label: 'User Management',
        },
        {
            value: 'search',
            label: 'Search',
        },
        {
            value: 'print-images',
            label: 'Print Images',
        },
        {
            value: 'mapping/list',
            label: 'Declaration Mapping',
        },
        {
            value: 'trim-images',
            label: 'Trim Images',
        },
        {
            value: '',
            label: 'Default',
        },
    ];

    constructor(props) {
        super(props);

        console.log(this.props);

        this.state = {
            user: this.props.location.state.user,
            hasError: false,
            password: '',
            errorMessage: '',
            defaultUrl: this.props.location.state.user.defaultUrl
        };

        this.handleSaveClicked = this.handleSaveClicked.bind(this);
        this.handleCancelClicked = this.handleCancelClicked.bind(this);
        this.changeUserStatusClicked = this.changeUserStatusClicked.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handleDefaultUrlChanged = this.handleDefaultUrlChanged.bind(this);
    }

    handleDefaultUrlChanged(e) {
        this.setState({ defaultUrl: e.target.value });
    }

    changeUserStatusClicked(id) {
        this.changeUserStatus();
    }

    handlePasswordChanged(e) {
        this.setState({ password: e.target.value })
    }

    handleCancelClicked() {
        this.props.history.replace("/user-management");
    }

    handleSaveClicked() {

        var data = {
            NewPassword: this.state.password.trim(),
            DefaultUrl: this.state.defaultUrl.trim()
        };

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let updateRoleUrl = new URL(urlService.GetApiUrl() + '/UserManagement/ChangePassword/' + this.state.user.id);

        const xhr = new XMLHttpRequest();

        xhr.open('Post', updateRoleUrl, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => {
            if (xhr.status === 200) {
                this.props.history.replace("/user-management");
            }
            else {
                this.handlePasswordChangeError(xhr);
            }
        };
        xhr.onerror = () => {
            this.handlePasswordChangeError(xhr);
        }
        xhr.send(JSON.stringify(data));
    }

    handlePasswordChangeError(xhr) {
        var responseObject = JSON.parse(xhr.responseText);
        this.setState({
            hasError: true,
            errorMessage: responseObject["message"]
        });
    }

    changeUserStatus() {

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let updateRoleUrl = new URL(urlService.GetApiUrl() + '/UserManagement/ChangeAccountStatus/' + this.state.user.id);

        const xhr = new XMLHttpRequest();

        xhr.open('Post', updateRoleUrl, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => {
            if (xhr.status === 200) {
                var currentUser = this.state.user;
                currentUser.isEnabled = !currentUser.isEnabled;
                this.setState({ user: currentUser });
            }
        };
        xhr.send();

    }
    render() {
        return (
            <main>
                <Header props={this.props} />
                <div className="search-content">
                    <h2>UPDATE USER PROFILE</h2>
                    <Box my={2}>
                        <p>* Microsoft Identity requires that passwords contain an uppercase character, lowercase character, a digit, and a non-alphanumeric character. Passwords must be at least six characters long.</p>
                    </Box>
                    {
                        this.state.hasError &&
                        <Box my={2}
                            style={{ color: '#ff0000' }}
                        >
                            <p>{this.state.errorMessage}</p>
                        </Box>
                    }
                    <Grid container spacing={3} direction="row" justify="flex-center" alignItems="center"
                        style={{ margin: 10 }}>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <Grid container spacing={3} direction="column" alignItems="stretch"
                                    style={{ margin: 10 }}>
                                    <Grid item>
                                        <TextField
                                            disabled
                                            label="User Email"
                                            name="useremail"
                                            className="form-control"
                                            size={'small'}
                                            value={this.state.user.email}
                                            aria-label="User Email"
                                            variant="outlined" >
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label="Default Url"
                                            select
                                            name="defaulturl"
                                            className="form-control"
                                            size={'small'}
                                            value={this.state.defaultUrl}
                                            onChange={this.handleDefaultUrlChanged}
                                            aria-label="Select default user page"
                                            variant="outlined" >
                                            {this.urlOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="password"
                                            label="User Password"
                                            type="password"
                                            name="password-control"
                                            className="form-control"
                                            size={'small'}
                                            value={this.state.password}
                                            onChange={this.handlePasswordChanged}
                                            aria-label="User Password"
                                            variant="outlined" >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Box m={2}>
                                    <Button
                                        style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                        size="large"
                                        variant="contained"
                                        onClick={this.handleSaveClicked}>
                                        UPDATE PROFILE
                                        </Button>
                                    <Button
                                        style={{ margin: 5, backgroundColor: '#b20000', color: '#ffffff' }}
                                        size="large"
                                        variant="contained"
                                        onClick={this.handleCancelClicked}>
                                        CANCEL</Button>
                                    {(this.state.user.isEnabled === false) &&
                                        <Button
                                            style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                            size="large"
                                            variant="contained"
                                            onClick={this.changeUserStatus.bind(this)}>
                                            UNBLOCK
                                            </Button>
                                    }
                                    {(this.state.user.isEnabled === true) &&
                                        <Button
                                            style={{ margin: 5, backgroundColor: '#b20000', color: '#ffffff' }}
                                            size="large"
                                            variant="contained"
                                            onClick={this.changeUserStatus.bind(this)}>
                                            BLOCK
                                            </Button>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                </div>
            </main>
        );
    }
}
import { Box, Button, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { Component } from 'react';
import { Header } from "../Header";
import { APIUrlService } from "../Services/APIUrlService";
import { TokenService } from "../TokenService";

export class RegisterUserPage extends Component {

    urlOptions = [
        {
            value: 'user-management',
            label: 'User Management',
        },
        {
            value: 'search',
            label: 'Search',
        },
        {
            value: 'print-images',
            label: 'Print Images',
        },
        {
            value: 'mapping/list',
            label: 'Declaration Mapping',
        },
        {
            value: 'trim-images',
            label: 'Trim Images',
        },
        {
            value: '',
            label: 'Default',
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            name: '',
            hasError: false,
            errorMessage: '',
            defaultUrl: ''
        };

        this.handleRegisterClicked = this.handleRegisterClicked.bind(this);
        this.handleCancelClicked = this.handleCancelClicked.bind(this);

        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handleUsernameChanged = this.handleUsernameChanged.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handleNameChanged = this.handleNameChanged.bind(this);
        this.handleDefaultUrlChanged = this.handleDefaultUrlChanged.bind(this);
    }

    handleDefaultUrlChanged(e) {
        this.setState({ defaultUrl: e.target.value });
    }

    handleEmailChanged(e) {
        this.setState({ email: e.target.value });
    }

    handleUsernameChanged(e) {
        this.setState({ username: e.target.value });
    }

    handlePasswordChanged(e) {
        this.setState({ password: e.target.value })
    }

    handleNameChanged(e) {
        this.setState({ name: e.target.value });
    }

    handleCancelClicked() {
        this.props.history.replace("/user-management");
    }

    handleRegisterClicked() {

        if (this.state.email === '' || this.state.username === '' || this.state.password === '' || this.state.name === '') {
            this.setState({ hasError: true, errorMessage: 'Please fill in all fields' });
            return;
        }
        else {
            this.setState({ hasError: false, errorMessage: '' });
        }

        var data = {
            Email: this.state.email.trim(),
            Password: this.state.password.trim(),
            CustomerName: this.state.name.trim(),
            DefaultUrl: this.state.defaultUrl.trim()
        };

        console.log(data);

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let updateRoleUrl = new URL(urlService.GetApiUrl() + '/Auth/Register');

        const xhr = new XMLHttpRequest();

        xhr.open('Post', updateRoleUrl, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => {
            if (xhr.status === 200) {
                this.props.history.replace("/user-management");
            }
            else {
                this.handleRegistrationError(xhr);
            }
        };
        xhr.onerror = () => {
            this.handleRegistrationError(xhr);
        }
        xhr.send(JSON.stringify(data));
    }

    handleRegistrationError(xhr) {
        var responseObject = JSON.parse(xhr.responseText);
        this.setState({
            hasError: true,
            errorMessage: responseObject["message"]
        });
    }

    render() {
        return (
            <main>
                <Header props={this.props} />
                <div className="search-content">
                    <h2>REGISTER NEW USER</h2>
                    <Box my={ 2}>
                        <p>* Microsoft Identity requires that passwords contain an uppercase character, lowercase character, a digit, and a non-alphanumeric character. Passwords must be at least six characters long.</p>
                        </Box>
                {
                    this.state.hasError &&
                    <Box my={2}
                        style={{ color: '#ff0000' }}
                    >
                        <p>{this.state.errorMessage}</p>
                    </Box>
                }
                <Grid container spacing={3} direction="row" justify="flex-center" alignItems="center"
                    style={{ margin: 10 }}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                        <Box>
                            <Grid container spacing={3} direction="column" alignItems="stretch"
                                style={{ margin: 10 }}>
                                <Grid item>
                                    <TextField
                                        label="User Email"
                                        name="useremail"
                                        className="form-control"
                                        size={'small'}
                                        value={this.state.email}
                                        onChange={this.handleEmailChanged}
                                        aria-label="User Email"
                                        variant="outlined" >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Person's Name"
                                        name="personName"
                                        className="form-control"
                                        size={'small'}
                                        value={this.state.name}
                                        onChange={this.handleNameChanged}
                                        aria-label="Person's Name"
                                        variant="outlined" >
                                    </TextField>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label="Default Url"
                                            select
                                            name="defaulturl"
                                            className="form-control"
                                            size={'small'}
                                            value={this.state.defaultUrl}
                                            onChange={this.handleDefaultUrlChanged}
                                            aria-label="Select default user page"
                                            variant="outlined" >
                                            {this.urlOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                <Grid item>
                                    <TextField
                                        id="password"
                                        label="User Password"
                                        type="password"
                                        name="password-control"
                                        className="form-control"
                                        size={'small'}
                                        value={this.state.password}
                                        onChange={this.handlePasswordChanged}
                                        aria-label="User Password"
                                        variant="outlined" >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Box m={2}>
                                <Button
                                    style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                    size="large"
                                    variant="contained"
                                    onClick={this.handleRegisterClicked}>
                                    REGISTER USER</Button>
                                <Button
                                    style={{ margin: 5, backgroundColor: '#ff0000', color: '#ffffff' }}
                                    size="large"
                                    variant="contained"
                                    onClick={this.handleCancelClicked}>
                                    CANCEL</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    </Grid>
                    </div>
            </main>
        );
    }
}
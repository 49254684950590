import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { Component } from 'react';
import 'react-image-lightbox/style.css';
import { APIUrlService } from "../Services/APIUrlService";
import { TokenService } from "../TokenService";

export class UserTable extends Component {

    constructor(props) {
        super(props);

        this.state = { userList: props.props };

        this.handleDemoteCustomerClicked = this.handleDemoteCustomerClicked.bind(this);
        this.handlePromoteAdminClicked = this.handlePromoteAdminClicked.bind(this);        
        this.editProfileClicked = this.editProfileClicked.bind(this);
    }

    editProfileClicked(id) {
        this.props.editProfileClicked(id);
    }

    handlePromoteAdminClicked(id) {
        this.updateUserRole(id, 'Admin');
    }

    handleDemoteCustomerClicked(id) {
        this.updateUserRole(id, 'Customer');
    }

    updateUserRole(id, role) {

        var data = {
            UserId: id,
            RoleName: role,
        };

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let updateRoleUrl = new URL(urlService.GetApiUrl() + '/UserManagement/ChangeRole');

        const xhr = new XMLHttpRequest();

        xhr.open('Post', updateRoleUrl, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => {
            if (xhr.status === 200) {
                var currentUsers = this.state.userList;
                var updatedUser = currentUsers.find(item => item.id === id);
                updatedUser.role = role;
                this.setState({ userList: currentUsers });
            }
        };
        xhr.send(JSON.stringify(data));
    }

    render() {
        return (
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell >ID</TableCell>
                            <TableCell >EMAIL</TableCell>
                            <TableCell >ROLE</TableCell>
                            <TableCell >IS ENABLED</TableCell>
                            <TableCell width='420' >ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.userList.map((row) => (
                            <React.Fragment key={row.id}>
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.role}</TableCell>
                                    <TableCell>{String(row.isEnabled)}</TableCell>
                                    <TableCell align="right">
                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                {(row.role === 'Admin') &&
                                                    <Button
                                                        style={{ margin: 5, backgroundColor: '#b20000', color: '#ffffff' }}
                                                        size="large"
                                                        variant="contained"
                                                        onClick={this.handleDemoteCustomerClicked.bind(this, row.id)}>
                                                        DEMOTE TO CUSTOMER</Button>
                                                }
                                                {(row.role === 'Customer') &&
                                                    <Button
                                                        style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                                        size="large"
                                                        variant="contained"
                                                        onClick={this.handlePromoteAdminClicked.bind(this, row.id)}>
                                                        PROMOTE TO ADMIN</Button>
                                                }
                                            </Grid>
                                            <Grid item>
                                                
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                                    size="large"
                                                    variant="contained"
                                                    onClick={this.editProfileClicked.bind(this, row.id)}>
                                                    EDIT PROFILE
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}
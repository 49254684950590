import { Box, Button, Collapse, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { Component } from 'react';
import CustomerLocalization from "../../../helpers/CustomerLocalization";
import { APIUrlService } from '../../Services/APIUrlService';
import { TokenService } from '../../TokenService';
import './SearchPage.css';

export class SearchTable extends Component {
    constructor(props) {
        super(props);

        this.state = { invoiceList: props.props };

        this.handleCertButtonClick = this.handleCertButtonClick.bind(this);
        this.handleOpenRowClick = this.handleOpenRowClick.bind(this);
        this.handleDeclarationClick = this.handleDeclarationClick.bind(this);
        this.handleUnmappedFileClicked = this.handleUnmappedFileClicked.bind(this);
    }

    handleUnmappedFileClicked(id, filename) {
        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/Invoice/UnmappedFile/' + id);

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url, true);
        xhr.responseType = "blob";
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => { console.log(xhr); this.handleUnmappedFileResponse(filename, xhr.response, xhr.status); };
        xhr.send();
    }

    handleUnmappedFileResponse(filename, responseObject, status) {
        if (status == 401) {
            this.props.history.replace("/logout");
            return;
        }

        const myBlob = new Blob([responseObject], {
            type: 'application/octet-stream'
        });

        var blobUrl = URL.createObjectURL(myBlob);

        var link = document.createElement('a');
        link.setAttribute('href', blobUrl);
        link.setAttribute('download', filename);
        link.click();
    }

    handleDeclarationClick(id, filename) {
        this.handleLoadDeclaration(id, filename);
    }

    handleLoadDeclaration(id, filename) {

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/DeclarationLetter/pdf/' + id);

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url, true);
        xhr.responseType = "blob";
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => { console.log(xhr); this.handleDeclarationResponse(filename, xhr.response, xhr.status); };
        xhr.send();
    }

    handleDeclarationResponse(filename, responseObject, status) {
        if (status == 401) {
            this.props.history.replace("/logout");
            return;
        }

        const myBlob = new Blob([responseObject], {
            type: 'application/octet-stream'
        });

        var blobUrl = URL.createObjectURL(myBlob);

        var link = document.createElement('a');
        link.setAttribute('href', blobUrl);
        link.setAttribute('download', filename);
        link.click();
    }

    handleOpenRowClick(id) {
        console.log("open clicked");
        var rows = this.state.invoiceList;
        var item = rows.find(item => item.id == id);
        item.isOpen = !item.isOpen;

        this.setState({ invoiceList: rows });
    }

    handleCertButtonClick(id, filename) {
        this.handleLoadCertificate(id, filename);
    }


    handleLoadCertificate(id, filename) {

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/Certificate/' + id);

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url, true);
        xhr.responseType = "blob";
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => { console.log(xhr); this.HandleCertificateResponse(filename, xhr.response, xhr.status); };
        xhr.send();
    }

    HandleCertificateResponse(filename, responseObject, status) {
        if (status == 401) {
            this.props.history.replace("/logout");
            return;
        }

        const myBlob = new Blob([responseObject], {
            type: 'application/octet-stream'
        });

        var blobUrl = URL.createObjectURL(myBlob);

        var link = document.createElement('a');
        link.setAttribute('href', blobUrl);
        link.setAttribute('download', filename);
        link.click();
    }

    render() {
        //console.log(this.state.invoiceList);
        var StyledTableCell = withStyles((theme) => ({
            head: {
                backgroundColor: '#176347',
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 14,
            },
        }))(TableCell);

        var StyledTableRow = withStyles((theme) => ({
            root: {
              /*
               * I couldn't figure out why this didnt work so I went for a simpler solution using the index and % 2 to determine if the green bg color class should be added.
               * '&:nth-of-type(odd)': {
                    backgroundColor: '#85C285',
                },
                */
            },
        }))(TableRow);
       

        var StyledDeclarationCell = withStyles((theme) => ({
            head: {
                backgroundColor: '#808080',
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 14,
            },
        }))(TableCell);

        return (
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >{CustomerLocalization.get("skuNoSearchResult")}</StyledTableCell>
                            <StyledTableCell align="right">PURCHASE ORDER #</StyledTableCell>
                            <StyledTableCell align="right">DESCRIPTION</StyledTableCell>
                            <StyledTableCell align="right">BRAND NAME</StyledTableCell>
                            <StyledTableCell align="right">OUR ORDER #</StyledTableCell>
                            <StyledTableCell align="right">OUR INVOICE #</StyledTableCell>
                            <StyledTableCell align="right">OUR LINE #</StyledTableCell>                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.invoiceList.map((row, index) => {
                            const colorClass = index % 2 == 0 ? "greenTableRow" : "";
                            return (
                                
                                <React.Fragment >
                                    <StyledTableRow key={row.id} className={colorClass}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.customersItemNo}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.customersOrderNo}</StyledTableCell>
                                    <StyledTableCell align="right">{row.itemDescription}</StyledTableCell>
                                    <StyledTableCell align="right">{row.sellToCustomerName}</StyledTableCell>
                                    <StyledTableCell align="right">{row.orderNo}</StyledTableCell>
                                    <StyledTableCell align="right">{(row.invoiceNo !== null) && row.invoiceNo.map((inv) => (<span key={inv}>{inv}<br /></span>))}</StyledTableCell>
                                    <StyledTableCell align="right">{(row.lineNo !== null) && row.lineNo.map((ln) => (<span key={ln}>{ln}<br /></span>))}</StyledTableCell>
                                </StyledTableRow>
                                {
                                    row.hasData && 
                                    <TableRow key='data${row.id}'>
                                        <TableCell colSpan={7}>

                                            {row.isMapped &&
                                                <Box>
                                                    <Box m={1}>
                                                        The order line is found in the following declaration letters:
                                                </Box>

                                                    <Box m={2}>
                                                        <TableContainer component={Paper} m={2}>
                                                            <Table aria-label="simple table" >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <StyledDeclarationCell align="right">Declaration Letter</StyledDeclarationCell>
                                                                        <StyledDeclarationCell align="right">Certificate</StyledDeclarationCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {row.mappedDeclarationLetters.map((declarationLetter) => (
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row">
                                                                                <Button
                                                                                    className="search-button"
                                                                                    size="medium"
                                                                                    variant="text"
                                                                                    color="default"
                                                                                    onClick={this.handleDeclarationClick.bind(this, declarationLetter.id, declarationLetter.fileName)}>
                                                                                    {declarationLetter.fileName}
                                                                                </Button>
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {declarationLetter.hasCertificate &&
                                                                                    <Button
                                                                                        className="search-button"
                                                                                        size="medium"
                                                                                        variant="text"
                                                                                        color="default"
                                                                                        onClick={this.handleCertButtonClick.bind(this, declarationLetter.certificateId, declarationLetter.certificateFileName)}>
                                                                                        {declarationLetter.certificateFileName}
                                                                                    </Button>
                                                                                }
                                                                                {!declarationLetter.hasCertificate &&
                                                                                    <span>Not Mapped</span>
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>

                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Box>
                                                </Box>
                                            }

                                            <Box m={2}>
                                                <Box m={1} onClick={this.handleOpenRowClick.bind(this, row.id)} style={{ cursor: 'pointer' }}>
                                                    <Grid container justify="flex-start" alignItems="center" spacing={1} >
                                                        <h6 >Downloads</h6>
                                                        {row.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </Grid>
                                                </Box>
                                                <Collapse in={row.isOpen} timeout="auto" unmountOnExit>
                                                    <Grid container justify="flex-start" spacing={2}>
                                                        {
                                                            row.unmappedDownloads.map((unmappedFile) => (
                                                                <Grid item key={unmappedFile}>
                                                                    <Button
                                                                        className="search-button"
                                                                        size="medium"
                                                                        variant="text"
                                                                        color="default"
                                                                        onClick={this.handleUnmappedFileClicked.bind(this, unmappedFile.id, unmappedFile.filename)}>{unmappedFile.filename}</Button>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Collapse>
                                            </Box>

                                        </TableCell>
                                    </TableRow>
                            
                       
                                    
                                }
                                {
                                    !row.hasData &&
                                    <TableRow key='data${row.id}'>
                                        <TableCell colSpan={7}>
                                            {
                                                !row.isLENZ ?
                                                    "No declaration letter or certificate is available at the moment. It is in process and will be uploaded as soon as possible" :
                                                    "This order line is Lenzing Viscose and no certificate is needed"
                                            }
                                        </TableCell>
                                    </TableRow>
                                }
                            </React.Fragment>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

        );
    }
}
import { GridList, GridListTile, GridListTileBar, IconButton, makeStyles, TablePagination } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { APIUrlService } from "../Services/APIUrlService";

export class ImageGrid extends Component {

    constructor(props) {
        super(props);

        this.state = { imageList: props.props, isLightBoxOpen: false, lightBoxFileName: '', lightBoxFolderName:'' };
        this.handleImageClick = this.handleImageClick.bind(this);
    }

    handleImageClick(filename, foldername) {
        this.setState({ lightBoxFileName: filename });
        this.setState({ lightBoxFolderName: foldername });
        this.setState({ isLightBoxOpen: true });
    }

    render() {
        console.log(this.state.imageList);

        const classes = makeStyles((theme) => ({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.paper,
            },
            gridList: {
                width: 500,
                height: 450,
            },
            icon: {
                color: 'rgba(255, 255, 255, 0.54)',
            },
        }));

        const api = new APIUrlService();
        return (            
            <div>

                <div className={classes.root}>
                    <GridList cellHeight={180} className={classes.gridList} cols={4}>
                        {this.state.imageList.map((tile) => (
                            <GridListTile key={tile.img} style={{ cursor: 'pointer' }}
                                onClick={this.handleImageClick.bind(this, tile.filename, tile.folderName)}>
                                <img src={`${api.getBaseUrl()}/StaticFiles/${tile.folderName}/${tile.filename}`} alt={tile.filename} />
                                <GridListTileBar
                                    title={tile.filename}
                                    subtitle={<span>{tile.folderName}</span>}
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </div>
                {this.state.isLightBoxOpen && (
                    <Lightbox
                        imageTitle={this.state.lightBoxFolderName + '/' + this.state.lightBoxFileName}
                        imageCaption={this.state.lightBoxFolderName + '/' + this.state.lightBoxFileName}
                        activeProps={{ width: '100%', height: '100%' }}
                        mainSrc={`${api.getBaseUrl()}/StaticFiles/${this.state.lightBoxFolderName}/${this.state.lightBoxFileName}`}
                        onCloseRequest={() => this.setState({ isLightBoxOpen: false })} />
                )}
            </div>
        );
    }
}
import { Box, Button, CircularProgress, TablePagination, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { Header } from '../../Header';
import { APIUrlService } from '../../Services/APIUrlService';
import { TokenService } from '../../TokenService';
import './SearchPage.css';
import { SearchTable } from './SearchTable';
import CustomerLocalization from "../../../helpers/CustomerLocalization";

export class SearchPage extends Component {
    static displayName = SearchPage.name;

    constructor(props) {
        super(props);
        this.state = {
            skuNo: '', orderNo: '', description: '', hasResults: false, searchResponse: null, isLoading: false,
            currentPage: 0,
            itemsPerPage: 10,
            totalItems: 0
        };

        this.handleSkuChange = this.handleSkuChange.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

        this.handleSearchClicked = this.handleSearchClicked.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleClearClicked = this.handleClearClicked.bind(this);
    }

    componentDidMount() {
        //this.loadSearchResults();
    }

    handleChangePage = (event, page) => {
        event.preventDefault();
        this.setState({ currentPage: page }, this.loadSearchResults);
    }

    handleChangeRowsPerPage(e) {
        this.setState({ itemsPerPage: e.target.value }, this.loadSearchResults);
    }

    handleClearClicked(e) {
        e.preventDefault();
        this.setState({ skuNo: '', orderNo: '', description: '', currentPage: 0 }, this.loadSearchResults);
    }

    handleSkuChange(e) {
        this.setState({ skuNo: e.target.value });
    }

    handleOrderChange(e) {
        this.setState({ orderNo: e.target.value });
    }

    handleDescriptionChange(e) {
        this.setState({ description: e.target.value });
    }

    handleSearchClicked(e) {
        e.preventDefault();

        this.setState({ currentPage: 0 }, this.loadSearchResults);
    }

    loadSearchResults() {
        this.setState({ isLoading: true, searchResponse: null, hasResults: false });

        const skuString = this.state.skuNo.trim();
        const orderString = this.state.orderNo.trim();
        const descriptionString = this.state.description.trim();

        let tokenService = new TokenService();
        let take = this.state.itemsPerPage;
        let skip = this.state.currentPage * this.state.itemsPerPage;
        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/Invoice/Search');
        url.searchParams.set('orderNo', orderString);
        url.searchParams.set('description', descriptionString);
        url.searchParams.set('itemNo', skuString);
        url.searchParams.set('take', take);
        url.searchParams.set('skip', skip);

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => { this.HandleSearchResponse(xhr); };
        xhr.onerror = () => { this.HandleSearchError(); };
        xhr.ontimeout = () => { this.HandleSearchError(); };
        xhr.send();
    }

    HandleSearchError() {
        this.setState({ isLoading: false });
        alert("Search request has failed.");
    }

    HandleSearchResponse(xhr) {
        this.setState({ isLoading: false });

        var responseObject = JSON.parse(xhr.responseText);

        if (responseObject["hasError"] === false) {
            if (typeof responseObject["data"] !== 'undefined' && responseObject["data"].length > 0) {
                console.log(responseObject);
                this.setState({
                    searchResponse: responseObject,
                    hasResults: true,
                    totalItems: responseObject["itemCount"]
                });
            }
        }
        else {
            this.setState({ hasResults: false });

            if (xhr.status == 401) {
                this.props.history.replace("/logout");
            }
            else {
                alert(responseObject["message"]);
            }
        }
    }

    render() {
        let tokenService = new TokenService();
        if (!tokenService.isAuthenticated()) {
            this.props.history.replace("/login");
            return (
                <main />
            );
        }
        else {
            let searchResults = this.state.hasResults
                ? <SearchTable props={this.state.searchResponse.data} />
                : <h3>No results</h3>;
            return (
                <main>
                    {/* <Header props={this.props}>
                        Test
                    </Header> */}
                    <div className="search-content">
                        <h2>Search for certificates</h2>
                        <p>*Only INVOICED orders can be found.</p>
                        <form onSubmit={this.handleSearchClicked}>
                            <TextField
                                name="sku-no"
                                className="form-control"
                                required
                                size={'small'}
                                value={this.state.skuNo}
                                onChange={this.handleSkuChange}
                                placeholder={CustomerLocalization.get("skuNoSearchField")}
                                aria-label={CustomerLocalization.get("skuNoSearchField")}
                                label={CustomerLocalization.get("skuNoSearchField")}
                                variant="outlined" />
                            <TextField
                                name="purchase-order"
                                className="form-control"
                                required
                                size={'small'}
                                value={this.state.orderNo}
                                onChange={this.handleOrderChange}
                                placeholder="PURCHASE ORDER"
                                aria-label="PURCHASE ORDER"
                                label="PURCHASE ORDER"
                                variant="outlined" />
                            <TextField
                                name="description"
                                className="form-control"
                                size={'small'}
                                value={this.state.description}
                                onChange={this.handleDescriptionChange}
                                placeholder="DESCRIPTION"
                                aria-label="DESCRIPTION"
                                label="DESCRIPTION"
                                variant="outlined" />
                            <Button
                                style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                type="submit"
                                className="search-button"
                                disableRipple={true}
                                size="large"
                                variant="contained"
                                startIcon={this.state.isLoading ? <CircularProgress thickness={6} size={22} color='inherit' /> : <SearchIcon />}
                            >
                                Search
                            </Button>
                            <Button
                                style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                                className="search-button"
                                disableRipple={true}
                                size="large"
                                variant="contained"
                                onClick={this.handleClearClicked}>
                                Clear
                            </Button>
                        </form>
                        <Box my={3}>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={this.state.totalItems}
                                page={this.state.currentPage}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={this.state.itemsPerPage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Box>
                        {searchResults}
                    </div>
                </main>
            );
        }
    }
}
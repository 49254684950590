import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { Component } from 'react';
import Logo from './Logo';
import { TokenService } from './TokenService';
import type { History } from 'history';
import getBuildMode from '../helpers/getBuildMode';
import CustomerLocalization from '../helpers/CustomerLocalization';

interface HeaderProps {
    props?: {
        history: History;
    },
    children?: React.ReactNode;
}

interface HeaderState {
    anchorEl: HTMLElement;
}

export class Header extends Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            anchorEl: null
        };

        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleMappingListClick = this.handleMappingListClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handlePrintImageClick = this.handlePrintImageClick.bind(this);
        this.handleUserManagementClick = this.handleUserManagementClick.bind(this);
        this.handleTrimImageClick = this.handleTrimImageClick.bind(this);
    }

    handleUserManagementClick() {
        this.props.props.history.push("/user-management");
    }

    handlePrintImageClick() {
        this.props.props.history.push("/print-images");
    }

    handleTrimImageClick() {
        this.props.props.history.push("/trim-images");
    }

    handleLogoutClick() {
        this.props.props.history.replace("/logout");
    }

    handleSearchClick() {
        this.props.props.history.push("/search");
    }

    handleMappingListClick() {
        this.props.props.history.push("/mapping/list");
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose() {
        this.setState({ anchorEl: null });
    };
    render() {
        const buildMode = getBuildMode();
        const debugString = buildMode !== "production" ? `(${buildMode[0].toUpperCase() + buildMode.slice(1)} Build)(Domain: ${CustomerLocalization.getDomain()})` : null;
        let tokenService = new TokenService();
        let userData = tokenService.getUserData();
        

        if (!tokenService.isAuthenticated()) {
            return (
                <header className="App-header">
                    <div>
                        <Logo />
                        {this.props.children}
                        {debugString}
                    </div>
                </header>
            );
        }
        else {
            return (
                <header className="App-header">
                    <div>
                        <Logo />
                        {this.props.children}
                        {debugString}
                    </div>
                    <div className="customer-info">
                        <IconButton aria-haspopup="true" onClick={this.handleClick}>
                            <MenuIcon fontSize="large" style={{ color: '#ffffff', borderWidth: '0px' }} />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}>
                            <Box mx={2} my={2}>
                                User: {userData['user']}
                            </Box>
                            {/*<MenuItem style={{ cursor:'default' }}>{userData['user']}</MenuItem>*/}
                            <MenuItem onClick={this.handleSearchClick}>Search for certificates Page</MenuItem>
                            <MenuItem onClick={this.handlePrintImageClick}>All over prints</MenuItem>
                            <MenuItem onClick={this.handleTrimImageClick}>Trims</MenuItem>
                            {userData.isAdmin &&
                                <MenuItem onClick={this.handleMappingListClick}>Declaration Letter Mapping</MenuItem>

                            }
                            {userData.isAdmin &&
                                <MenuItem onClick={this.handleUserManagementClick}>User Management</MenuItem>
                            }
                            <MenuItem onClick={this.handleLogoutClick}>LOGOUT</MenuItem>
                        </Menu>

                    </div>
                </header>

            );
        }
    }
}
import './Logo.css'
import React from 'react'

function Logo() {
    return (
        <img width="1367" height="1000" className="logo-img"
             src="/UTG_LOGO_2020-14-e1595933939992.png"
             alt="#unitedtextilegroup" loading="lazy"
             sizes="(max-width: 1367px) 100vw, 1367px"
             srcSet="/UTG_LOGO_2020-14-e1595933939992.png 1367w, /UTG_LOGO_2020-14-e1595933939992-300x219.png 300w, UTG_LOGO_2020-14-e1595933939992-1024x749.png 1024w, /UTG_LOGO_2020-14-e1595933939992-768x562.png 768w, /UTG_LOGO_2020-14-e1595933939992-161x118.png 161w"
             data-ll-status="loaded" />
    )
}

export default Logo

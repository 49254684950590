import './App.css';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Login } from './components/Login';
import { Logout } from './components/Logout';
import Splash from './components/Splash';
import { MappingListPage } from './components/MappingListPage';
import { EditMappingPage } from './components/EditMappingPage';
import { SearchPage } from './components/Search/CertificateSearch/SearchPage';
import { PrintImageGallery } from './components/PrintImages/PrintImageGallery';
import { UserManagementPage } from './components/UserManagement/UserManagementPage';
import { RegisterUserPage } from './components/UserManagement/RegisterUserPage';
import { EditProfilePage } from './components/UserManagement/EditProfilePage';
import { TrimImageGallery } from './components/TrimImages/TrimImageGallery';
import SearchCertificatePage from './components/Search/SearchCertificatePage';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (            
                <Switch>
                <Route exact path='/' component={Splash} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/logout' component={Logout} />
                <Route path='/search' component={SearchCertificatePage} />
                <Route path='/search/data' component={SearchCertificatePage} />
                <Route path='/mapping/list' component={MappingListPage} />
                <Route path='/mapping/edit' component={EditMappingPage} />
                <Route path='/print-images' component={PrintImageGallery} />
                <Route path='/trim-images' component={TrimImageGallery} />
                <Route path='/user-management' component={UserManagementPage} />
                <Route path='/user-register' component={RegisterUserPage} />
                <Route path='/user-edit' component={EditProfilePage} />
                </Switch>
        );
    }
}

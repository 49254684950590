import { UnstyledButton } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import React from "react";
import { Header } from "../Header";
import { APIUrlService } from "../Services/APIUrlService";
import { TokenService } from "../TokenService";
import { SearchPage } from "./CertificateSearch/SearchPage";
import DataSearchPage from "./DataSearch/DataSearch";
import "./SearchCertificatePage.css";

export default function SearchCertificatePage(props: any) {
  const path = location.pathname;
  const pageType = path === "/search/data" ? "data" : "certificate";
  // @ts-ignore
  const [buttons, showButtons] = useSetState<boolean>(null);

  if (buttons === null) {
    const api = new APIUrlService();
    let url = api.GetApiUrl() + "/Invoice/SetUpAccessData";
    let tokenService = new TokenService();
    let user = tokenService.getUserData();
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + user.token
      }
    }).then(async res => {
      showButtons(res.ok);
    })
  }
  return <>
    <Header props={props}>
      {buttons ? <>
        <span className="header-certificate-search-container">
          <UnstyledButton onClick={() => {
            props.history.push("/search");
          }} className={[
            "header-certificate-search-link",
            pageType === "certificate" ? "header-certificate-search-link-active" : ""
          ].join(" ")}>Search for Certificates</UnstyledButton>
          <UnstyledButton onClick={() => {
            props.history.push("/search/data");
          }} className={[
            "header-certificate-search-link",
            pageType === "data" ? "header-certificate-search-link-active" : ""
          ].join(" ")}>Certificates Data</UnstyledButton>
        </span>
      </> : <></>}
    </Header>
    {
      pageType === "certificate" ?
        <SearchPage {...props} />
        :
        <DataSearchPage {...props} />
    }
  </>;
}
import { TokenService } from "../components/TokenService";

export const customerParser = {
  "zalando": (email: string) => email.toLowerCase().endsWith("zalando.de"),
  "hummel": (email: string) => email.toLowerCase().includes("hummel")// || email == "kan@it-kartellet.dk",
}

export const getCustomers = () => Object.keys(customerParser);

type Customer = keyof typeof customerParser;

class CustomerLocalization {
  private domain: string;
  private customer: Customer;
  private static _cache: Record<keyof typeof customerParser, any> = {} as any;
  public constructor(domain?: string) {
    if (!domain) {
      const tokenService = new TokenService();
      domain = tokenService.getUserData().user;
    }
    this.domain = domain;
    this.customer = Object.keys(customerParser).find(customer => Boolean(domain) && customerParser[customer](domain)) as Customer;
    if (!this.customer) {
      this.customer = "zalando";
    }
    let localization = require(`../localization/${this.customer}.json`);
    this.localization = localization;
  }

  public static load(domain?: string) {
    domain || (domain = new TokenService().getUserData().user);
    return CustomerLocalization._cache[domain] = (CustomerLocalization.getCache(domain) || new CustomerLocalization(domain));
  }

  public static getCache(domain: string) {
    const c = this._cache[domain];
    console.log("domain cache:", domain, !!c);
    return c;
  }

  private localization: any;

  public get(key: string, defaultValue?: string) {
    return this.localization?.[key] || defaultValue || key;
  }

  /**
   * Loads from the stored customer localization
   * @param key Key to get from localization
   * @param defaultValue Default value if key is not found
   */
  public static get(key: string, defaultValue?: string) {
    const localization = this.load();
    return localization.localization?.[key] || defaultValue || key;
  }

  public getDomain() {
    return this.customer;
  }
  public static getDomain() {
    const localization = this.load();
    return localization.customer;
  }
}
export default CustomerLocalization;
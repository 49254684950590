import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { Header } from '../Header';
import { APIUrlService } from '../Services/APIUrlService';
import { TokenService } from '../TokenService';
import { UserTable } from './UserTable';

export class UserManagementPage extends Component {
    static displayName = UserManagementPage.name;

    constructor(props) {
        super(props);

        this.state = {
            userList: null,
            hasResults: false
        };
        this.handleRegisterClicked = this.handleRegisterClicked.bind(this);
        this.editProfileClicked = this.editProfileClicked.bind(this);
    }

    editProfileClicked(id) {
        var selectedUser = this.state.userList.data.find(item => item.id === id);

        this.props.history.push({
            pathname: "/user-edit",
            state: { user: selectedUser }
        });
    }

    handleRegisterClicked() {
        this.props.history.push("/user-register");
    }

    componentDidMount() {
        this.loadUserList();
    }

    loadUserList() {

        this.setState({
            isLoading: true,
            hasResults: false,
            userList: null
        });

        let tokenService = new TokenService();

        let urlService = new APIUrlService();

        let url = new URL(urlService.GetApiUrl() + '/UserManagement');

        const xhr = new XMLHttpRequest();

        xhr.open('Get', url, true);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + tokenService.getUserData()["token"])
        xhr.onload = () => { this.HandleSearchResponse(xhr); };
        xhr.onerror = () => { this.HandleSearchError(); };
        xhr.ontimeout = () => { this.HandleSearchError(); };
        xhr.send();
    }

    HandleSearchError() {
        this.setState({ isLoading: false });
        alert("Search request has failed.");
    }

    HandleSearchResponse(xhr) {
        this.setState({ isLoading: false });

        var responseObject = JSON.parse(xhr.responseText);

        if (responseObject["hasError"] === false) {
            if (typeof responseObject["data"] !== 'undefined' && responseObject["data"].length > 0) {
                this.setState({
                    userList: responseObject,
                    hasResults: true
                });
            }
        }
        else {
            this.setState({ hasResults: false });

            if (xhr.status == 401) {
                this.props.history.replace("/logout");
            }
            else {
                alert(responseObject["message"]);
            }
        }
    }

    //#endregion 

    render() {
        let searchResults = this.state.hasResults
            ? <UserTable props={this.state.userList.data} editProfileClicked={this.editProfileClicked} />
            : <h3>No results</h3>;
        return (
            <main>
                <Header props={this.props} />
                <div className="search-content">
                    <h2>USER MANAGEMENT</h2>
                    <Box justifyContent="flex-end" display="flex" my={2}>
                        <Button
                            style={{ margin: 5, backgroundColor: '#176347', color: '#ffffff' }}
                            size="large"
                            variant="contained"
                            onClick={this.handleRegisterClicked}>
                            REGISTER USER</Button>
                    </Box>
                    {searchResults}
                </div>
            </main>
        );
    }
}
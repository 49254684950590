import { UserData } from "./UserData";

export class TokenService {
    userdataStorageKey = "utgonline_userdata";

    setUserData(data: UserData) {
        localStorage.setItem(this.userdataStorageKey, JSON.stringify(data));
    }

    isAuthenticated() {
        return localStorage.getItem(this.userdataStorageKey) !== null;
    }

    getUserData() {
        const data = JSON.parse(localStorage.getItem(this.userdataStorageKey));
        const user = new UserData();
        Object.assign(user, data);
        return user;
    }

    logout() {
        localStorage.clear();
    }
}